<template>
  <div>
    <h1>{{ $t('views.copyright.h1_title') }}</h1>
    <p>{{ $t('views.copyright.p_intro') }}</p>
    <v-simple-table>
      <template #default>
        <thead>
          <tr>
            <th class="text-left">{{ $t('views.copyright.th_image') }}</th>
            <th class="text-left">{{ $t('views.copyright.th_copyright_owner') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in copyright.images" :key="index">
            <td class="py-2 text-center">
              <v-img max-height="80" max-width="120" :src="webpExtension(`${settings.cdn.url}${item.filename}`)" />
            </td>
            <td>{{ item.copyrightOwner }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="text-center my-3">
      <Adslot ad-unit="content-last" ad-class="adslot__content-last" />
    </div>
    <div class="text-center">
      <btn-home class="my-3"></btn-home>
    </div>
  </div>
</template>

<script>
import { webp } from '@/mixins'
import { copyright, settings } from '@/config'
import BtnHome from '@/components/btn/Home'
import Adslot from '@/components/Adslot.vue'

export default {
  name: 'Copyright',
  components: { Adslot, BtnHome },
  mixins: [webp],
  computed: {
    copyright() {
      return copyright
    },
    settings() {
      return settings
    },
  },
}
</script>

<style lang="scss"></style>
