<template>
  <div>
    <h1>{{ $t('views.status_code_404.h1_title') }}</h1>
    <p>{{ $t('views.status_code_404.p_text') }}</p>
    <div class="text-center my-3">
      <Adslot ad-unit="content-last" ad-class="adslot__content-last" />
    </div>
    <div class="text-center">
      <btn-home class="my-3"></btn-home>
    </div>
  </div>
</template>

<script>
import Adslot from '@/components/Adslot.vue'
import BtnHome from '@/components/btn/Home'
export default {
  name: 'StatusCode404',
  components: { Adslot, BtnHome },
}
</script>

<style></style>
